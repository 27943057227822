@import './styles/_styles.sass';

.App {
    
}

a.normal-link {
    color: $theme-color;
    text-decoration: underline;
    text-decoration-color: $theme-color;

    span {
        color: $theme-color;
    }
}

a.normal-link:hover {
    color: $theme-color-hover;
    text-decoration: underline;
    text-decoration-color: $theme-color-hover;

    span:hover {
        color: $theme-color-hover;
    }
}