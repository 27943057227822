@import '../../styles/_styles';

$break-small: 450px;

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: calc(100% - 75px);

    .main-content {
        max-width: 1000px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        height: 100%;
        padding-bottom: 70px;

        .social-icons-container {
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 100px;
            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: $small-screen) {
                max-width: 200px; }

            .white-background-container {
                position: relative;
                margin: 0 6px;

                .white-background {
                    position: absolute;
                    width: 45px;
                    height: 42px;
                    margin: 13px 0 0 9px;
                    background-color: white;
                    z-index: -1;

                    &.steam {
                        margin: 6px 0 0 0;
                        width: 60px;
                        height: 50px; }

                    @media screen and (max-width: $small-screen) {
                        width: 30px;
                        height: 29px;
                        margin: 10px 0 0 6px;

                        &.steam {
                            margin: 6px 0 0 0;
                            width: 37px;
                            height: 33px; } } }

                &:hover {
                    .white-background {
                        background-color: #b5b5d4; } } }

            .social-icon {
                font-size: 70px;

                @media screen and (max-width: $small-screen) {
                    font-size: 45px; }

                &.discord {
                    margin-top: 4px;
                    color: #7289DA; }

                &.twitter {
                    color: #1DA1F2; }

                &.youtube {
                    font-size: 65px;
                    color: #FF0000;
                    width: 75px;

                    @media screen and (max-width: $small-screen) {
                        font-size: 45px;
                        width: 50px; } }

                &.reddit {
                    color: #FF5700; }

                &.steam {
                    color: #171a21;
                    margin: 0 -7px 0 0; } } }

        .main-button-container {
            justify-content: center;
            align-items: center; }

        .main-button {
            width: 400px;
            height: 80px;
            background-color: #000233;
            font-size: 30px;

            @media screen and (max-width: $small-screen) {
                width: 200px;
                height: 50px;
                font-size: 14px; } } }

    .divider {
        width: 100%;

        &.main {
            margin-bottom: 15px; }

        &.filters {
            margin-top: 15px;
            margin-bottom: 15px; } } }
