.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    max-width: 1000px;
    width: 100%;
    height: 0;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiCircularProgress-root {
        margin-top: 56.25%;
        width: 80px;
        height: 80px; } }


.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute; }
