@import '../../styles/_styles';

.about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 150px 25px 25px 25px;

    @media screen and (max-width: $small-screen) {
        margin-top: 60px; }

    a {
        color: $theme-color;
        text-decoration: none;

        &:hover {
            color: $theme-color-hover;
            text-decoration: underline;
            text-decoration-color: $theme-color-hover; } }


    .section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 900px;
        background-color: black;
        opacity: 0.8;
        color: white;
        padding: 20px;
        border-radius: 25px;

        &.contact {
            margin-top: 35px; }

        .title {
            font-size: 38px;
            margin-bottom: 15px;

            @media screen and (max-width: $small-screen) {
                font-size: 23px; } }

        .content {
            font-size: 21px;

            @media screen and (max-width: $small-screen) {
                font-size: 14px; } } } }
