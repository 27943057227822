@import './../../styles/_styles';

.header {
    //background-color: #efefef
    background-color: black;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 5px 15px;

    .section {
        display: flex;
        align-content: center;
        justify-content: center;

        .links-container {
            height: 32px;
            margin-left: 20px;
            display: flex;
            align-content: center;
            justify-content: center; }

        &.left {
            .title {} }


        &.right {
            .header-icon {
                margin-right: 5px;
                margin-left: 5px;

                &:hover {
                    color: $theme-color; } } } }

    .link {
        height: 100%;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 20px; } }
